



















































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { object, number, string } from 'yup'
import { Title, Validation, Textbox, Dropdown, Label, Lever, HttpGet, Errors, Check, TextboxWithCharlimit } from '../../../lib/mavejs'
import { url } from '@/functions'
import { Functions } from '@/mixins/Functions'
import { VideoCampaign } from '@/interfaces/form/VideoCampaign'
import TextboxWithCharlimitExWarning from '@/lib/mavejs/components/form/TextboxWithCharlimitExWarning.vue'

@Component({
  components: { TextboxWithCharlimitExWarning, Title, Validation, Textbox, Dropdown, Label, Lever, HttpGet, Check, TextboxWithCharlimit },
  mixins: [Functions, Errors],
  methods: {
    number () {
      return number
    },
    url: url
  }
})
export default class SettingsForm extends Vue {
  @Prop() store!: VideoCampaign;

  showCustomCta = false
  hideCustomCtaInput = true
  hideCustomCtaDropdown = false
  hideCustomDescription = false
  hideCustomDescriptionInput = true
  hideCustomDescriptionDropdown = false
  ctas = []
  descriptions = []

  mounted () {
    if (this.store.campaign.customCta) {
      this.showCustomCta = true
      this.hideCustomCtaInput = false
      this.hideCustomCtaDropdown = true
    }
    if (this.store.campaign.customDescription) {
      this.hideCustomDescription = true
      this.hideCustomDescriptionInput = false
      this.hideCustomDescriptionDropdown = true
    }
    if (this.model.discountAmount == null) {
      this.model.discountAmount = 0
    }

    if (this.model.placementexclusion == null) {
      this.model.placementexclusion = false
    }
  }

  toggleCta () {
    this.showCustomCta = !this.showCustomCta
    this.$emit('input', this.showCustomCta)
    this.model.cta = this.$store.state.ctas.data[0].value
    if (this.hideCustomCtaInput === true) {
      this.hideCustomCtaInput = false
      this.hideCustomCtaDropdown = true
    } else {
      this.hideCustomCtaInput = true
      this.hideCustomCtaDropdown = false
    }
  }

  toggleDescription () {
    this.hideCustomDescription = !this.hideCustomDescription
    this.$emit('input', this.showCustomCta)
    this.model.description = this.$store.state.descriptions.data[0].value
    if (this.hideCustomDescriptionInput === true) {
      this.hideCustomDescriptionInput = false
      this.hideCustomDescriptionDropdown = true
    } else {
      this.hideCustomDescriptionInput = true
      this.hideCustomDescriptionDropdown = false
    }
  }

  model = this.store.settings

  schema = object({
    amount: number().min(1).max(100),
    cta: string().required(),
    description: string().required(),
    discountAmount: number().min(0).max(100).required()
  })
}
