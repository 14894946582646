import { isEmpty, isSet } from 'lodash'
import { getGeoWherePasted, splitYoutubeUrls, selectNodeWherePasted, getPastedGeoId, getAllInputFieldsText, findDuplicates } from './PasteAdsData'

export class PasteMultiple {
  getMultiple () {
    document.addEventListener('paste', function (e: ClipboardEvent) {
      const GeoInputFields = document.querySelectorAll('*[name^="geo"]')
      let youtubeUrls = []
      let pastedId
      let pastedNode
      let pastedGeoName
      let fullPasted
      for (let i = 0; i < GeoInputFields.length; ++i) {
        if (e.target === GeoInputFields[i]) {
          fullPasted = GeoInputFields[i].getAttribute('name')
          pastedGeoName = getGeoWherePasted(GeoInputFields[i])
          youtubeUrls = splitYoutubeUrls(e);
          [].forEach.call(document.querySelectorAll('*[name^=' + pastedGeoName + ']'), (node) => {
            const mainNode = selectNodeWherePasted(node, e)
            const mainId = getPastedGeoId(mainNode)
            if (mainNode !== undefined && mainNode !== null) {
              setTimeout(function () {
                (mainNode as HTMLInputElement).value = youtubeUrls[0]
              }, 0)
            }

            if (mainId !== undefined) {
              pastedId = mainId
              pastedNode = mainNode
            }
          })
        }
      }

      const customEvent = new CustomEvent('linksPasted', { detail: { links: youtubeUrls, pastedId: pastedId, pastedNode: pastedNode, pastedGeoName: pastedGeoName, fullPasted: fullPasted } })
      document.dispatchEvent(customEvent)
    })
  }

  setGeoId (pastedNode) {
    let geoId
    if (pastedNode.closest('.geoAltCard') !== null && pastedNode.closest('.geoAltCard') !== undefined) {
      // TODO: fix this hardcoded number
      const numberClass = pastedNode.closest('.geoAltCard').classList[5]
      geoId = numberClass.slice(-1)
      return geoId
    } else {
      const geoName = pastedNode.name
      const geoNumber = geoName.substring(0, geoName.indexOf('-'))
      geoId = geoNumber.replace('geo', '')
      return geoId
    }
  }

  colorDoubles () {
    document.addEventListener('keyup', function (e) {
      const pastedGeoName = getGeoWherePasted(e.target as HTMLInputElement)
      const allInputs = document.querySelectorAll('*[name^=' + pastedGeoName + ']')
      const allText = getAllInputFieldsText(pastedGeoName)
      const set = new Set(allText)
      let duplicates = findDuplicates(allText, set)
      duplicates = [...new Set(duplicates)]
      for (let i = 0; i < allInputs.length; ++i) {
        if (duplicates.includes((allInputs[i] as HTMLInputElement).value)) {
          allInputs[i].parentElement.style.border = 'solid orange'
        } else {
          allInputs[i].parentElement.style.border = 'none'
        }
      }
    })
  }
}
