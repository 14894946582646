

































import { Component, Vue, Prop, Watch } from 'vue-property-decorator'

@Component
export default class TextboxWithCharlimit extends Vue {
  @Prop() label!: string
  @Prop() name!: string
  @Prop({ default: 'text' }) type!: string
  @Prop({ default: '' }) value!: string | null | undefined;
  @Prop() maxLength!: number
  @Prop() placeholder!: string
  @Prop({ default: '' }) validation!: string

  hasExclamationMark () {
    return typeof this.value === 'string' && this.value.includes('!')
  }

  mounted () {
    this.charactersRemain()
  }

  @Watch('value', { deep: true })
  charactersRemain () {
    if (this.value !== undefined && this.value !== null) {
      const characterCount = this.value.length
      const current = document.getElementById(this.name + 'currentComment')
      const inputField = document.getElementById(this.name)
      current.innerText = String(characterCount)
      if (characterCount > this.maxLength) {
        current.style.color = 'red'
        inputField.style.color = 'red'
      } else {
        current.style.color = 'black'
        inputField.style.color = 'black'
      }
    }
  }

  checkExclamationMark () {
    if (this.hasExclamationMark()) {
      const current = document.getElementById(this.name + 'currentComment')
      const inputField = document.getElementById(this.name)
      if (current) {
        current.style.color = 'red'
      }
      if (inputField) {
        inputField.style.color = 'red'
      }
    }
  }
}

