









import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { Title, SearchSelect, Validation, Functions, Errors } from '../../../lib/mavejs'
import { alphabetical, url } from '@/functions'
import { object, array } from 'yup'

@Component({
  components: { Title, SearchSelect, Validation },
  mixins: [Functions, Errors],
  methods: { url, alphabetical }
})
export default class GeoForm extends Vue {
  @Prop() store!: object;

  model = this.store

  schema = object().shape({
    geos: array().min(1).required('Should have at least a geo')
  });
}
