









































import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { format } from 'date-fns'
import { OverlayButton, Validation, Textbox, Functions, SubTitle, Title, Errors, Datefield, Dropdown, UnfoldedDatefield, Button } from '../../../lib/mavejs'
import { object, number, string } from 'yup'
import { VideoCampaign } from '@/interfaces/form/VideoCampaign'
import { Obfuscator } from '@/classes/campaign/Obfuscator'
import { HideCpaDropdown } from '@/classes/campaign/HideCpaDropdown'

@Component({
  components: { OverlayButton, Validation, Textbox, SubTitle, Title, Datefield, Dropdown, UnfoldedDatefield, Button },
  mixins: [Functions, Errors],
  methods: { format: format }
})
export default class CampaignForm extends Vue {
  @Prop() store!: VideoCampaign;

  model = this.store ? this.store.campaign : null
  hiddenClass = ''
  startDate = ''
  toggleStatus = ''

  schema = object({
    name: string().required(),
    description: string().nullable(),
    comment: string().nullable(),
    bidStrategy: string().nullable(),
    customCta: string().nullable(),
    customDescription: string().nullable(),
    product: string().required(),
    cpa: number().min(0).required().typeError('Must be a number'),
    budget: number().min(0).required().typeError('Must be a number'),
    creator: string().required()
  })

  mounted () {
    this.model.campaignType = 1
    if (this.model) {
      if (this.model.date === null) {
        this.hiddenClass = 'hidden'
        this.toggleStatus = 'Off'
      } else {
        this.startDate = this.model.date
        this.toggleStatus = 'On'
      }
    }
    const checkCpaDrop = new HideCpaDropdown()
    checkCpaDrop.checkDropdownValue()
  }

  handleDate (value) {
    this.model.date = format(value, 'yyyy-MM-dd')
  }

  obfuscate (product) {
    const obfuscator = new Obfuscator(product)

    this.model.name = obfuscator.obfuscate()
  }

  // TODO add toggle here
  hideStartDate () {
    // eslint-disable-next-line eqeqeq
    if (this.hiddenClass == 'hidden') {
      this.hiddenClass = ''
      this.model.date = this.startDate
      this.toggleStatus = 'On'
    } else {
      this.hiddenClass = 'hidden'
      this.model.date = null
      this.toggleStatus = 'Off'
    }
  }

  @Watch('model.date')
  logger () {
    if (this.model.date !== null) {
      this.startDate = this.model.date
    }
  }
}

