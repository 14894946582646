export function getGeoWherePasted (geoInput) {
  let pastedGeoName = geoInput.getAttribute('name')
  if (pastedGeoName !== undefined && pastedGeoName !== null) {
    pastedGeoName = pastedGeoName.split('-')[0]
  }
  return pastedGeoName
}

export function splitYoutubeUrls (e) {
  let youtubeUrls = []
  let data = e.clipboardData.getData('Text')

  if (data.includes('https')) {
    data = data.split('https').join(';https')
    data = data.replace(/(\r\n|\n|\r)/gm, '')
    youtubeUrls = data.split(';')
    youtubeUrls.shift()
    return youtubeUrls
  }

  data = data.trim()
  data = data.replace(/(\r\n|\n|\r)/gm, '-split-')
  youtubeUrls = data.split('-split-')
  return youtubeUrls
}

export function selectNodeWherePasted (node, e) {
  let mainNode
  if (node === e.target) {
    mainNode = node
  }
  return mainNode
}

export function getPastedGeoId (mainNode) {
  let mainId
  if (mainNode !== undefined) {
    const mainName = mainNode.name
    mainId = mainName.slice(-1)
  }
  return mainId
}

export function getAllInputFieldsText (pastedGeoName) {
  const allInputs = document.querySelectorAll('*[name^=' + pastedGeoName + ']')
  let allText = []
  for (let i = 0; i < allInputs.length; ++i) {
    allText.push((allInputs[i] as HTMLInputElement).value)
  }
  allText = allText.filter(item => item)
  return allText
}

export function findDuplicates (allText, set) {
  const duplicates = allText.filter(item => {
    if (set.has(item)) {
      set.delete(item)
    } else {
      return item
    }
  })
  return duplicates
}
